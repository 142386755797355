import React from "react"
import { Container, Row, Col } from "reactstrap"

class CareerBanner extends React.Component {
  render() {
    const tagline = "Careers",
      title = `Foretheta is a fast-growing company building leading-edge products. Our tech stack is built with 
      Python and services provided by AWS. Our frontend builds currently use JavaScript and ReactJS. We do a lot 
      of data science and machine learning. We don't fill roles; we look for people with passion.`

    return (
      <div className="career mb-6">
        <div className="career-banner pt-6 pb-6 mb-4 text-white text-justify">
          <Container fluid>
            <Row>
              <Col xl={{ size: 10, offset: 1 }} lg={12}>
                <h1 className="mb-5 font-weight-bold">{tagline} </h1>
                <h3 className="mb-5 text-justify">{title}</h3>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
}
export default CareerBanner