import React from "react"
import { Button } from "reactstrap"

class ApplyNow extends React.Component {
  render() {
    return (
      <div className="text-center my-4">
        <Button
          onClick={() => {
            window.location = "mailto:recruiting@foretheta.com"
          }}
        >
          Apply Now
        </Button>
        <br />
        <br />
        or email us at <p className="email-text">recruiting@foretheta.com</p>
      </div>
    )
  }
}
export default ApplyNow
