import React from "react"
import { Container } from "reactstrap"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import CareerBanner from "./Career/CareerBanner"
import ApplyNow from "./Career/ApplyNow"
import Layout from "./Layout"

const components = {
  h5: props => <h5 {...props} className="font-weight-bold opening-title" />
}

export default function CareersLayout({ data: { mdx } }) {
  return (
    <Layout>
      <CareerBanner />
      <Container>
        <h4 className="font-weight-bold pb-4 opening-title" id={mdx.id}>
          {mdx.frontmatter.title}
        </h4>
        <MDXProvider components={components}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </Container>
      <ApplyNow />
    </Layout>
  )
}

export const pageQuery = graphql`
  query CareerQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
      }
      body
    }
  }
`
